











































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
@Component({
  name: 'Intellij',
})
export default class intellij extends VueBase {}
